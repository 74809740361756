//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDCvI_wKRVpmBDG7vKBswCHohXPUlvSZ7M",
  authDomain: "jolly-roger-a4f6e.firebaseapp.com",
  projectId: "jolly-roger-a4f6e",
  storageBucket: "jolly-roger-a4f6e.firebasestorage.app",
  messagingSenderId: "979803680460",
  appId: "1:979803680460:web:ac26c96d47d00f946fdc69",
  measurementId: "G-7PTV1R04K0",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
