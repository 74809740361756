import React from 'react';
import TasksMenu from './TasksMenu';

const TasksPage = () => {
  return (
    <div className="bg-[#222222] text-white min-h-screen">
      {/* Основной контейнер */}
      <div className="w-full flex justify-center flex-col px-4">

        {/* Контент */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Используем TasksMenu */}
          <TasksMenu />
          </div>
      </div>
    </div>
  );
};

export default TasksPage;